import {
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_AUDIOBOOK_DETAILS } from "../../MProjectConst";
import Loader from "../../Components/Loader";
import axios from "axios";
import classes from "../../Assets/Styles/Global.module.css";
import GlobalButton from "../../Components/Buttons/GlobalButton";
import { ToastContainer } from "react-toastify";
import { secondaryColor } from "../../utils/constants";
import { getLanguageName } from "../../utils/generalFunctions";
import { Store } from "../../Store";

const AudiobookWorkPages = () => {
    const { dispatch: ctxDispatch } = useContext(Store);
    const params = useParams();
    const { projectId } = params;
    const navigate = useNavigate();
    const [dataLoader, setDataLoader] = useState(false);
    const [projectDetails, setProjectDetails] = useState(null);

    const handleWork = (role, language) => {
        // Save project details to context and localStorage first
        ctxDispatch({ type: "PROJECT_FILE", payload: projectDetails });
        localStorage.setItem("projectFile", JSON.stringify(projectDetails));

        // Then navigate based on the role
        if (role === "performancedirection") {
            navigate(`/audiobooks/performancedirection/${projectId}/${language}`);
        } else if (role === "finalqa") {
            navigate(`/audiobooks/finalqa/${projectId}/${language}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                const response = await axios.get(`${GET_AUDIOBOOK_DETAILS}/${projectId}`);
                setProjectDetails(response.data.project);
                setDataLoader(false);
            } catch (err) {
                console.error(err);
                setDataLoader(false);
            }
        };

        fetchData();
    }, [projectId]);

    const workTypes = [
        { id: "performancedirection", name: "Performance Direction" },
        { id: "finalqa", name: "Final QA Review" }
    ];

    return (
        <Grid item container justifyContent="center">
            <Loader Load={dataLoader} />
            <ToastContainer position="top-center" theme="dark" />

            <Grid item container xs={9.5} marginTop={7} direction="row" marginBottom={10}>
                <Grid item container direction="row" marginBottom={8.0} gap={2}>
                    <Grid item className={classes["header"]}>
                        {projectDetails?.name} (Work Pages)
                    </Grid>
                    <Grid item marginTop={0.5}>
                        <GlobalButton
                            onClick={() => navigate(`/audiobooks/details/${projectId}`)}
                        >
                            Details
                        </GlobalButton>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>ACTIONS</TableCell>
                                {/* <TableCell>View Original Content</TableCell> */}
                                <TableCell>Original Language</TableCell>
                                <TableCell>Final Languages</TableCell>
                                <TableCell>Creation Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectDetails && workTypes.map((type, index) => (
                                <TableRow key={index}>
                                    <TableCell>{projectDetails.name}</TableCell>
                                    <TableCell>{type.name}</TableCell>
                                    <TableCell>
                                        <Select
                                            size="small"
                                            color="primary"
                                            defaultValue="Start Working"
                                        >
                                            <MenuItem value="Start Working">Start Working</MenuItem>
                                            {projectDetails.finalLanguages?.map((language, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={language}
                                                    onClick={() => handleWork(type.id, language)}
                                                >
                                                    {getLanguageName(language)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    {/* <TableCell>
                                        <a
                                            style={{
                                                textDecoration: "none",
                                                color: `${secondaryColor}`,
                                            }}
                                            href={projectDetails.currentVersion}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Original Content
                                        </a>
                                    </TableCell> */}
                                    <TableCell>
                                        {getLanguageName(projectDetails.originalLanguage)}
                                    </TableCell>
                                    <TableCell width="30%">
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            gap={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {projectDetails.finalLanguages?.map((language, index) => (
                                                <Grid
                                                    xs={5.5}
                                                    item
                                                    container
                                                    key={index}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    width="300px"
                                                    height="32px"
                                                    sx={{
                                                        background: "lightgrey",
                                                        fontWeight: "400",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <Grid item>{getLanguageName(language)}</Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        {new Date(projectDetails.createdAt.seconds * 1000).toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AudiobookWorkPages;