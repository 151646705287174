// AudiobookPerformanceDirection.jsx
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Grid, Typography, Fab, Zoom, TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GET_AUDIOBOOK_DIALOGUES, MERGING_AUDIOBOOK } from '../../MProjectConst';
import Loader from '../../Components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import PopoverControls from '../../Components/AudioTools/PopoverControls';
import AudiobookPlayer from '../../Components/AudioTools/AudiobookPlayer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';

const AudiobookPerformanceDirection = () => {
    const params = useParams();
    const { projectId } = params;
    const [dialogues, setDialogues] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [selectedDialogueId, setSelectedDialogueId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [playingIndex, setPlayingIndex] = useState(null); // Track the playing dialogue
    const [selectedIndex, setSelectedIndex] = useState(null); // New state to track selected index
    const [currentIndex, setCurrentIndex] = useState(0);
    const [adjacentDialogueId, setAdjacentDialogueId] = useState(null);
    const [selectedDialogue, setSelectedDialogue] = useState(null);
    const [paragraphInfo, setParagraphInfo] = useState(null);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [showNumbers, setShowNumbers] = useState(false);
    const [versionName, setVersionName] = useState('');
    const [isMerging, setIsMerging] = useState(false);

    // Ref to control AudiobookPlayer
    const audiobookPlayerRef = useRef(null);
    const mainPlayerStateRef = useRef({
        isPlaying: false,
        wasPlaying: false // Add this to track state before sentence play
    });

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                const response = await axios.get(`${GET_AUDIOBOOK_DIALOGUES}/${projectId}`);
                if (response.data.success) {
                    setDialogues(response.data.dialogues || []);
                }
            } catch (err) {
                console.error(err);
            }
            setDataLoader(false);
        };
        fetchData();
    }, [projectId]);

    useEffect(() => {
        if (setPlayingIndex) {
            setPlayingIndex(currentIndex); // Update the playingIndex in the parent component
        }
    }, [currentIndex, setPlayingIndex]);

    const sortedDialogues = useMemo(() => {
        return [...dialogues].sort((a, b) => a.sequence - b.sequence);
    }, [dialogues]);

    const groupedDialogues = useMemo(() => {
        return sortedDialogues.reduce((acc, dialogue) => {
            const { paragraph_id } = dialogue;
            if (!acc[paragraph_id]) {
                acc[paragraph_id] = [];
            }
            acc[paragraph_id].push(dialogue);
            return acc;
        }, {});
    }, [sortedDialogues]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 300; // Adjust this value to change when the button appears
            setShowScrollTop(window.pageYOffset > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Handle word click and track the selected index
    const handleWordClick = (event, dialogue, index) => {
        if (selectedDialogueId === dialogue.id) {
            setSelectedDialogueId(null);
            setAnchorEl(null);
            setAudioUrl('');
            setSelectedIndex(null);
            setAdjacentDialogueId(null);
        } else {
            const leftDialogue = sortedDialogues[index - 1] || null;
            const rightDialogue = sortedDialogues[index + 1] || null;

            // Get paragraph information
            const paragraphInfo = {
                currentParagraphId: dialogue.paragraph_id,
                leftParagraphId: leftDialogue?.paragraph_id,
                rightParagraphId: rightDialogue?.paragraph_id
            };

            setSelectedDialogueId(dialogue.id);
            setAnchorEl(event.currentTarget);
            setAudioUrl(dialogue.audio_url || '');
            setSelectedIndex(index);
            setAdjacentDialogueId({
                left: leftDialogue?.id || null,
                right: rightDialogue?.id || null,
                audioDuration: dialogue.audio_duration
            });
            setSelectedDialogue(dialogue);
            setParagraphInfo(paragraphInfo); // Add a new state for paragraph info
        }
    };

    // Function to handle "Play From Here" action
    const handlePlayFromHere = () => {
        if (selectedIndex !== null && audiobookPlayerRef.current) {
            audiobookPlayerRef.current.playFromIndex(selectedIndex);
            handleClosePopover();
        }
    };

    const handleClosePopover = () => {
        setSelectedDialogueId(null);
        setAnchorEl(null);
        setAudioUrl('');
        setSelectedIndex(null);
    };

    const handleVersionChange = (version) => {
        if (!selectedDialogue?.audio_versions?.[version]) {
            console.error('Version data not available');
            return;
        }

        setDialogues(prev => prev.map(d =>
            d.id === selectedDialogue.id
                ? {
                    ...d,
                    audio_url: selectedDialogue.audio_versions[version].url || d.audio_url,
                    audio_duration: selectedDialogue.audio_versions[version].duration || d.audio_duration,
                    currentVersion: version
                }
                : d
        ));
    };

    const handleAudioGenerated = (dialogueId, versionData) => {
        setDialogues(prevDialogues => prevDialogues.map(dialogue => {
            if (dialogue.id === dialogueId) {
                return {
                    ...dialogue,
                    audio_versions: {
                        ...dialogue.audio_versions,
                        [versionData.version]: {
                            url: versionData.url,
                            duration: versionData.duration,
                            created_at: versionData.created_at
                        }
                    }
                };
            }
            return dialogue;
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleMergeAudio = async () => {
        if (!versionName.trim()) {
            toast.error('Please enter a version name');
            return;
        }

        setIsMerging(true);
        try {
            const response = await axios.post(MERGING_AUDIOBOOK, {
                projectId,
                projectName: 'CC_Test_Cantor3', // Assuming project name is available in dialogues
                langCode: 'en', // Or get from props/state if multiple languages are supported
                version: versionName,
                emails: ['mohammad@y76.io'], // Replace with actual user email
                submitterName: 'User Name' // Replace with actual user name
            });

            if (response.data.success) {
                toast.success('Merge process started successfully');
            } else {
                toast.error(response.data.message || 'Failed to start merge process');
            }
        } catch (error) {
            console.error('Error merging audio:', error);
            toast.error(error.response?.data?.message || 'Failed to merge audio');
        } finally {
            setIsMerging(false);
        }
    };

    return (
        <Grid container spacing={3} className="w-full">
            <Loader Load={dataLoader} />
            <ToastContainer position="top-center" theme="dark" />

            {/* AudiobookPlayer Section */}
            <Grid
                item
                xs={12}
                className="flex justify-center"
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    paddingBottom: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
            >
                <div className="w-full max-w-4xl">
                    <AudiobookPlayer
                        ref={audiobookPlayerRef}
                        dialogues={sortedDialogues}
                        setPlayingIndex={setPlayingIndex}
                        stateRef={mainPlayerStateRef}
                        showNumbers={showNumbers}
                        onNumberingChange={setShowNumbers}
                    />
                </div>
            </Grid>

            {/* Text Area Section */}
            <Grid
                item
                xs={12}
                style={{
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                }}
            >
                <div
                    style={{
                        width: '80%',
                        maxWidth: '100%', // Prevent horizontal overflow
                        minHeight: '500px',
                        padding: '16px',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        fontFamily: 'inherit',
                        overflowY: 'auto', // Enable vertical scrolling
                        overflowX: 'hidden', // Disable horizontal scrolling
                        boxSizing: 'border-box',
                    }}
                >
                    {Object.keys(groupedDialogues).map((paragraphId) => (
                        <Typography
                            key={paragraphId}
                            paragraph
                            style={{
                                wordWrap: 'break-word', // Ensure long words break
                            }}
                        >
                            {groupedDialogues[paragraphId].map((dialogue, dialogueIndex) => {
                                const globalIndex = sortedDialogues.findIndex(d => d.id === dialogue.id);
                                const words = dialogue.text.split(/(\s+)/);
                                const isPlaying = dialogue.sequence === playingIndex; // Check if the dialogue is currently playing
                                const isSelected = dialogue.id === selectedDialogueId; // Check if the dialogue is selected

                                return (
                                    <span
                                        key={`${dialogue.id}-${dialogueIndex}`}
                                        style={{
                                            backgroundColor: isPlaying ? '#ffeb3b' : isSelected ? '#d1e7dd' : 'transparent',
                                            padding: '2px',
                                            borderRadius: '3px',
                                            transition: 'background-color 0.3s',
                                            display: 'inline',
                                            marginBottom: '4px',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {showNumbers && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: '0.75rem',
                                                    color: '#666',
                                                    marginRight: '2px',
                                                    userSelect: 'none',
                                                }}
                                            >
                                                {globalIndex + 1}:
                                            </Typography>
                                        )}
                                        {words.map((word, i) =>
                                            word.trim() !== '' ? (
                                                <span
                                                    key={i}
                                                    data-sequence={dialogue.sequence}
                                                    onClick={(e) => handleWordClick(e, dialogue, globalIndex)}
                                                    style={{ cursor: 'pointer', userSelect: 'none' }}
                                                    title="Click to view dialogue details"
                                                >
                                                    {word}
                                                </span>
                                            ) : (
                                                word
                                            )
                                        )}
                                    </span>
                                );
                            })}
                        </Typography>
                    ))}
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20,
                            width: 'auto',
                            zIndex: 1000,
                            alignItems: 'center',
                            gap: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        <Grid item>
                            <TextField
                                size="small"
                                label="Version Name"
                                value={versionName}
                                onChange={(e) => setVersionName(e.target.value)}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={handleMergeAudio}
                                disabled={isMerging}
                                sx={{ height: '40px' }}
                            >
                                {isMerging ? 'Merging...' : 'Merge Audio'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Zoom in={showScrollTop}>
                    <Fab
                        color="primary"
                        size="small"
                        onClick={scrollToTop}
                        sx={{
                            position: 'fixed',
                            bottom: 90,
                            right: 16,
                            zIndex: 1000,
                        }}
                        aria-label="scroll back to top"
                    >
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Zoom>
            </Grid>

            <PopoverControls
                open={Boolean(selectedDialogueId)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                audioUrl={audioUrl}
                onPlayFromHere={handlePlayFromHere}
                selectedDialogueId={selectedDialogueId || ''}
                adjacentDialogueId={adjacentDialogueId || { left: null, right: null }}
                projectId={projectId || ''}
                audioDuration={adjacentDialogueId?.audioDuration || 0}
                dialogue={selectedDialogue}
                onVersionChange={handleVersionChange}
                onAudioGenerated={handleAudioGenerated}
                mainPlayerStateRef={mainPlayerStateRef}
                mainPlayerRef={audiobookPlayerRef}
                paragraphInfo={{
                    currentParagraphId: selectedDialogue?.paragraph_id,
                    leftParagraphId: dialogues.find(d => d.id === adjacentDialogueId?.left)?.paragraph_id,
                    rightParagraphId: dialogues.find(d => d.id === adjacentDialogueId?.right)?.paragraph_id
                }}
            />
        </Grid>
    );
};

export default AudiobookPerformanceDirection;
