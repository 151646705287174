import React from "react";
import { Select, MenuItem, Grid } from "@mui/material";
import GlobalButton from "../Buttons/GlobalButton";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../utils/constants";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        width: "0.1px",
        borderRadius: 4,
        position: "relative",
        border: "none",
        fontSize: 16,
        backgroundColor: `${primaryColor}`,
        padding: "0px 0px 5px 4px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: `${secondaryColor}`,
        fontFamily: "Livvic",
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select": {
        backgroundColor: `${primaryColor} !important`,
        color: `${secondaryColor} !important`,
    },
}));

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: `none !important`,
    color: `none !important`,
    fontSize: "15px",
    "&.Mui-selected": {
        backgroundColor: "grey !important",
        color: `none !important`,
    },
    "&:focus": {
        backgroundColor: `grey !important`,
        color: `${primaryColor} !important`,
    },
});

const AudiobookRolesNavBar = ({
    projectFile,
    handleWork,
    getLanguageName,
}) => {
    const navigate = useNavigate();

    const workTypes = [
        { id: "performancedirection", name: "Performance Direction" },
        { id: "finalqa", name: "Final QA Review" }
    ];

    return (
        <StyledSelect input={<BootstrapInput />}>
            {workTypes.map((type, index) => (
                <StyledMenuItem key={index}>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid>{type.name}</Grid>
                        <Grid>
                            <StyledSelect
                                size="small"
                                defaultValue="Start Working"
                                sx={{ ml: 4 }}
                            >
                                <StyledMenuItem value="Start Working">
                                    Start Working
                                </StyledMenuItem>
                                {projectFile?.finalLanguages?.map((language, langIndex) => (
                                    <StyledMenuItem
                                        key={langIndex}
                                        value={language}
                                        onClick={() => handleWork(type.id, language)}
                                    >
                                        {getLanguageName(language)}
                                    </StyledMenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    </Grid>
                </StyledMenuItem>
            ))}
            <StyledMenuItem
                onClick={() => navigate(`/audiobooks/details/${projectFile.id}`)}
            >
                Details page
            </StyledMenuItem>
            <StyledMenuItem onClick={() => navigate(`/actions/${projectFile.id}`)}>
                Action page
            </StyledMenuItem>
        </StyledSelect>
    );
};

export default AudiobookRolesNavBar;