import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import axios from "axios";
import { styled } from "@mui/system";
import classes from "../../Assets/Styles/Global.module.css";
import { GET_AUDIOBOOK_DETAILS } from "../../MProjectConst";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "left",
    fontWeight: "bold",
}));

const AudiobookDetailsPage = () => {
    const [dataLoader, setDataLoader] = useState(false);
    const [audiobookDetails, setAudiobookDetails] = useState(null);
    const { projectId } = useParams(); // Extract projectId from route parameters

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                const response = await axios.get(`${GET_AUDIOBOOK_DETAILS}/${projectId}`);
                setAudiobookDetails(response.data.project);
            } catch (error) {
                console.error("Error fetching audiobook details:", error);
            } finally {
                setDataLoader(false);
            }
        };
        fetchData();
    }, [projectId]);

    return (
        <Grid item container justifyContent="center" marginBottom={10}>
            <Loader Load={dataLoader} />
            <Grid item container xs={8} marginTop={5} direction="column">
                <Grid item className={classes["header"]}>
                    Audiobook Details
                </Grid>
                {audiobookDetails && (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Field</StyledTableCell>
                                    <StyledTableCell>Details</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>{audiobookDetails.name}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Original Language</StyledTableCell>
                                    <StyledTableCell>{audiobookDetails.originalLanguage}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Final Languages</StyledTableCell>
                                    <StyledTableCell>
                                        {audiobookDetails.finalLanguages.join(", ")}
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>{audiobookDetails.status}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Created At</StyledTableCell>
                                    <StyledTableCell>
                                        {new Date(audiobookDetails.createdAt.seconds * 1000).toLocaleString()}
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Employees</StyledTableCell>
                                    <StyledTableCell>
                                        {audiobookDetails.employees.map((employee) => (
                                            <div key={employee.id}>
                                                {employee.name} - {employee.role}
                                            </div>
                                        ))}
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default AudiobookDetailsPage;
