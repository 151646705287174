import {
    Grid,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import classes from "../../Assets/Styles/Global.module.css";
import GlobalButton from "../../Components/Buttons/GlobalButton.js";
import { primaryColor, secondaryColor } from "../../utils/constants.js";
import {
    convertToDate,
    getLanguageName,
    getTaskName,
} from "../../utils/generalFunctions.js";
import { Store } from "../../Store.js";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { GET_AUDIOBOOKS } from "../../MProjectConst.js";

const AudiobookListScreen = () => {
    const navigate = useNavigate();
    const [audiobooks, setAudiobooks] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [lastDocId, setLastDocId] = useState(null);
    const { dispatch: ctxDispatch } = useContext(Store);

    useEffect(() => {
        ctxDispatch({ type: "PROJECT_FILE_CLEAR" });
    }, []);

    const fetchAudiobooks = async (pageSize = 10) => {
        setDataLoader(true);
        try {
            const queryParams = new URLSearchParams({
                pageSize,
                ...(lastDocId && { lastDocId }),
                sortBy: 'createdAt',
                sortOrder: 'desc'
            });

            const response = await axios.get(`${GET_AUDIOBOOKS}?${queryParams}`);

            if (page === 1) {
                setAudiobooks(response.data.data.audiobooks);
            } else {
                setAudiobooks(prev => [...prev, ...response.data.data.audiobooks]);
            }

            setHasMore(response.data.data.hasMore);
            setLastDocId(response.data.data.lastDocId);
            setDataLoader(false);
        } catch (err) {
            console.error(err);
            setDataLoader(false);
        }
    };

    useEffect(() => {
        fetchAudiobooks();
    }, [page]);

    const loadMore = () => {
        if (!dataLoader && hasMore) {
            setPage(prev => prev + 1);
        }
    };

    const filteredAudiobooks = useMemo(() => {
        return audiobooks.filter((audiobook) =>
            audiobook.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [audiobooks, searchTerm]);

    return (
        <Grid item container justifyContent="center">
            <ToastContainer position="top-center" theme="dark" />

            <Grid
                item
                container
                xs={10}
                marginTop={7}
                direction="row"
                marginBottom={10}
            >
                <Grid
                    item
                    xs={3}
                    sx={{
                        fontSize: "40px",
                        fontWeight: "900",
                    }}
                    className={classes["header"]}
                >
                    View Audiobooks
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    marginBottom={1.5}
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <TextField
                            type="text"
                            size="small"
                            placeholder="Search..."
                            style={{ width: "100%", marginBottom: "20px" }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                </Grid>

                {dataLoader && audiobooks.length === 0 ? (
                    <Grid item xs={12} sx={{ width: 1200 }}>
                        {Array.from({ length: 10 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                animation="wave"
                                width="100%"
                                height={100}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Table sx={{ minWidth: 900 }}>
                            <TableHead sx={{ backgroundColor: `${secondaryColor}` }}>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Work Pages</TableCell>
                                    <TableCell>Languages</TableCell>
                                    <TableCell>Creation Date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredAudiobooks.map((audiobook, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            style={{
                                                fontWeight: "600",
                                                maxWidth: "220px",
                                            }}
                                        >
                                            <Grid container item direction="column" gap={1.5}>
                                                <Grid item>{audiobook.name}</Grid>
                                                <Grid
                                                    container
                                                    item
                                                    justifyContent="center"
                                                    alignContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item backgroundColor={`${secondaryColor}`}>
                                                        <IconButton
                                                            size="small"
                                                            sx={{
                                                                color: `${primaryColor}`,
                                                                "&:hover": {
                                                                    backgroundColor: `${primaryColor}`,
                                                                    color: `${secondaryColor}`,
                                                                },
                                                            }}
                                                            onClick={() => navigate(`/audiobooks/edit/${audiobook.id}`)}
                                                        >
                                                            <EditNoteIcon sx={{ fontSize: "1.2rem" }} />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item bgcolor="#D3D3D3" xs={3.3} height="29px">
                                                        <Grid item marginTop={0.7}>
                                                            {getLanguageName(audiobook.originalLanguage)}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                        <TableCell>
                                            <GlobalButton
                                                onClick={() => navigate(`/audiobooks/workpages/${audiobook.id}`)}
                                                height="45px"
                                                width="120px"
                                                style={{ fontSize: "13px", padding: "10px 10px" }}
                                            >
                                                Work Pages
                                            </GlobalButton>
                                        </TableCell>

                                        <TableCell width="10px">
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                gap={1}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {audiobook.finalLanguages?.map((language, index) => (
                                                    <Grid
                                                        xs={6}
                                                        item
                                                        container
                                                        key={index}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Select
                                                            size="small"
                                                            color="primary"
                                                            style={{ minWidth: "200px" }}
                                                            value={language.toUpperCase()}
                                                        >
                                                            <MenuItem value={language.toUpperCase()} disabled={true}>
                                                                {language.toUpperCase()}
                                                            </MenuItem>
                                                        </Select>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TableCell>

                                        <TableCell>
                                            <Grid container width="100%" justifyContent="center">
                                                <Grid item>
                                                    {new Date(audiobook.createdAt.seconds * 1000).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit'
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                        <TableCell>
                                            <Grid
                                                item
                                                container
                                                justifyContent="center"
                                                gap={1.3}
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <GlobalButton
                                                        color="primary"
                                                        height="45px"
                                                        width="120px"
                                                    // onClick={() => navigate(`/actions/${audiobook.id}`)}
                                                    >
                                                        Actions
                                                    </GlobalButton>
                                                </Grid>
                                                <Grid item>
                                                    <Select
                                                        size="small"
                                                        color="primary"
                                                        defaultValue="View Employee"
                                                    >
                                                        <MenuItem value="View Employee" disabled={true}>
                                                            Members
                                                        </MenuItem>
                                                        {audiobook.employees?.map((employee, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={employee.name}
                                                                disabled
                                                            >
                                                                {employee.name} : {employee.role}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>

                        {hasMore && (
                            <Grid container justifyContent="center" marginTop={2}>
                                <GlobalButton
                                    onClick={loadMore}
                                    disabled={dataLoader}
                                    height="45px"
                                    width="120px"
                                >
                                    Load More
                                </GlobalButton>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default AudiobookListScreen;