import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import classes from "../Assets/Styles/Global.module.css";
import { Store } from "../Store";

function LandingAdminPage() {
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(Store);

  // useEffect(() => {
  //   const getAccessToken = async () => {
  //     await axios
  //       .get(GET_ACESS_TOKEN)
  //       .then((response) => {
  //         ctxDispatch({
  //           type: "SAVE_ACESS_TOKEN",
  //           payload: response.data.access_token,
  //         });
  //         localStorage.setItem(
  //           "accessToken",
  //           JSON.stringify(response.data.access_token)
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // toast.error("server is down");
  //       });
  //   };

  //   getAccessToken();
  // }, []);

  useEffect(() => {
    ctxDispatch({ type: "PROJECT_FILE_CLEAR" });
    // localStorage.removeItem("projectFile");
  }, []);

  return (
    <Grid item container justifyContent="center" marginBottom={49.7}>
      <Grid
        item
        container
        xs={7}
        marginTop={8}
        // bgcolor="red"
        direction="column"
        gap={6}
      >
        <Grid
          item
          container
          // justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/createproject");
          }}
        >
          <Grid
            item
            container
            width="100px"
            height="100px"
            alignContent="center"
            justifyContent="center"
            marginRight={2}
            marginBottom={3}
            borderRadius="20px"
            className={classes["bigIcons"]}
          >
            <AddCircleIcon fontSize="large" />
          </Grid>
          <Grid item className={classes["labels"]}>
            New Project
          </Grid>
        </Grid>
        <Grid
          item
          container
          // justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/projectlist");
          }}
        >
          <Grid
            item
            container
            width="100px"
            height="100px"
            alignContent="center"
            justifyContent="center"
            marginRight={2}
            marginBottom={3}
            borderRadius="20px"
            className={classes["bigIcons"]}
          >
            <ViewListIcon fontSize="large" />
          </Grid>
          <Grid item className={classes["labels"]}>
            View Video Projects
          </Grid>
        </Grid>
        <Grid
          item
          container
          // justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/audiobooks");
          }}
        >
          <Grid
            item
            container
            width="100px"
            height="100px"
            alignContent="center"
            justifyContent="center"
            marginRight={2}
            marginBottom={3}
            borderRadius="20px"
            className={classes["bigIcons"]}
          >
            <ViewListIcon fontSize="large" />
          </Grid>
          <Grid item className={classes["labels"]}>
            View Audio Projects
          </Grid>
        </Grid>
        {/* <Grid item container fontSize={40} marginBottom={2}>
          Templates
        </Grid>
        <Grid item container direction="column">
          <Grid item container fontSize={20}>
            Audio
          </Grid>
          <Grid item container direction="row">
            <Grid
              item
              container
              width="100px"
              height="100px"
              // bgcolor="#5570dc"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              sx={{ cursor: "pointer" }}
              // onClick={() => {
              //   navigate("/work/transcriber");
              // }}
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>Transcription</Grid>
            </Grid>
            <Grid
              item
              container
              width="100px"
              height="100px"
              // bgcolor="#5570dc"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              sx={{ cursor: "pointer" }}
              // onClick={() => {
              //   navigate("/work/translator");
              // }}
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>Translation</Grid>
            </Grid>
            <Grid
              item
              container
              width="100px"
              height="100px"
              // bgcolor="#5570dc"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // onClick={() => {
              //   navigate("/work/qualityassurance");
              // }}
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Review</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item container fontSize={20}>
            Video
          </Grid>
          <Grid item container direction="row">
            <Grid
              item
              container
              width="100px"
              height="100px"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // direction="column"
              // justifyContent="space-between"
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Skits</Grid>
            </Grid>
            <Grid
              item
              container
              width="100px"
              height="100px"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Show</Grid>
            </Grid>
            <Grid
              item
              container
              width="100px"
              height="100px"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Episode</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item container fontSize={20}>
            Basic
          </Grid>
          <Grid item container direction="row">
            <Grid
              item
              container
              width="100px"
              height="100px"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Character</Grid>
            </Grid>
            <Grid
              item
              container
              width="100px"
              height="100px"
              bgcolor="grey"
              marginRight={3}
              borderRadius="20px"
              // marginBottom={3}
            >
              <AddCircleIcon fontSize="large" />
              <Grid item>New Script</Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default LandingAdminPage;
