import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Assets/Styles/Global.module.css";
import Loader from "../../Components/Loader.js";
import { GET_USERS, GET_AUDIOBOOK_DETAILS, EDIT_AUDIOBOOK } from "../../MProjectConst.js";
import { languagesCode } from "../../Data/LanguagesCode.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { getLanguageName, getTaskName } from "../../utils/generalFunctions.js";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import UserSelectAutocomplete from "../../Components/UserSelectAutoComplete/index.js";
import { formatInputs } from "../../utils/generalFunctions.js";

const AudiobookEditPage = () => {
    const params = useParams();
    const { id: projectId } = params;
    const navigate = useNavigate();

    // States
    const [videoFile, setVideoFile] = useState("");
    const [textFile, setTextFile] = useState("");
    const [dialogueAudioFile, setDialogueAudioFile] = useState("");
    const [fxFile, setFxFile] = useState("");
    const [employees, setEmployees] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [projectFinalLangs, setProjectFinalLangs] = useState([]);
    const [displaySelection, setDisplaySelection] = useState([]);
    const [upLoader, setUpLoader] = useState(false);
    const [textFileUrl, setTextFileUrl] = useState("");
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedRole, setSelectedRole] = useState(""); // Keep track of selected role

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch audiobook details by ID
                const response = await axios.get(`${GET_AUDIOBOOK_DETAILS}/${projectId}`);
                const data = response.data.project;

                setProjectName(data.name);
                setProjectFinalLangs(data.finalLanguages || []);
                setDisplaySelection(data.employees || []);
                setVideoFile(data.videoFile || "");
                setTextFile(data.textFile || "");
                setDialogueAudioFile(data.dialogueAudioFile || "");
                setFxFile(data.fxFile || "");
                setTextFileUrl(data.textFileUrl || ""); // Add this line
            } catch (err) {
                console.error("Error fetching audiobook details:", err);
            }
        };
        fetchData();
    }, [projectId]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(GET_USERS);
                setEmployees(response.data.usersArray || []);
            } catch (err) {
                console.error("Error fetching users:", err);
            }
        };
        fetchEmployees();
    }, []);

    const handleUpdateAudiobook = async (e) => {
        e.preventDefault();
        setUpLoader(true);

        try {
            await axios.post(EDIT_AUDIOBOOK, {
                projectId,
                projectName,
                finalLanguages: projectFinalLangs,
                employees: displaySelection,
                videoFile,
                textFile,
                dialogueAudioFile,
                fxFile,
                userId: "exampleUserId", // Replace with dynamic user ID if available
            });
            toast.success("Audiobook updated successfully!");
            // window.location.reload()
        } catch (error) {
            console.error("Error updating audiobook:", error);
            toast.error("Failed to update audiobook. Please try again.");
        } finally {
            setUpLoader(false);
        }
    };


    const handleRemoveEmployee = (name) => {
        setDisplaySelection(displaySelection.filter((item) => item.name !== name));
    };

    const handleRemoveLanguage = (code) => {
        setProjectFinalLangs(projectFinalLangs.filter((languageCode) => languageCode !== code));
    };


    return (
        <Grid container justifyContent="center" style={{ fontFamily: "var(--global-text-font)" }}>
            <Loader Load={upLoader} />
            <ToastContainer position="top-center" theme="dark" />

            <Grid container xs={7} marginTop={7} direction="column">
                <form onSubmit={handleUpdateAudiobook}>
                    <Grid item marginBottom={5} className={classes["header"]}>
                        Update Audiobook
                    </Grid>

                    <Grid item xs={8} marginBottom={3}>
                        <TextField
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(formatInputs(e.target.value))}
                            label="Audiobook Name"
                        />
                    </Grid>

                    <Grid container xs={12} marginBottom={3} alignItems="center" direction="row">
                        <Grid item>
                            <FormControl style={{ width: "220px" }}>
                                <Select
                                    value=""
                                    onChange={(e) => {
                                        if (!projectFinalLangs.includes(e.target.value)) {
                                            setProjectFinalLangs([...projectFinalLangs, e.target.value]);
                                        }
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        Add Final Language
                                    </MenuItem>
                                    {languagesCode.map((language) => (
                                        <MenuItem key={language.code} value={language.code}>
                                            {language.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container gap={1} marginTop={2}>
                            {projectFinalLangs.map((language, index) => (
                                <Grid key={index} sx={{ bgcolor: "lightblue", padding: "5px", borderRadius: "5px" }}>
                                    <span>{getLanguageName(language)}</span>
                                    <IconButton onClick={() => handleRemoveLanguage(language)} size="small" color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" mb={2}>
                        <Grid item xs={3}>
                            <UserSelectAutocomplete
                                onUserSelect={(user) => setSelectedEmployee(user)}
                                existingUsers={displaySelection}
                                fetchUsersUrl={GET_USERS}
                                applyWidth={false}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Select Role</InputLabel>
                                <Select
                                    value={selectedRole}
                                    onChange={(event) => setSelectedRole(event.target.value)}
                                    label="Select Role"
                                >
                                    <MenuItem value="performancedirection">Performance Direction</MenuItem>
                                    <MenuItem value="translator">Translator</MenuItem>
                                    {/* Add more roles as needed */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    if (!selectedEmployee || !selectedRole) {
                                        toast.error("Please select an employee and role.");
                                        return;
                                    }

                                    // Add the employee to the list
                                    setDisplaySelection([
                                        ...displaySelection,
                                        { name: selectedEmployee.name, id: selectedEmployee.id, role: selectedRole },
                                    ]);

                                    // Reset selection
                                    setSelectedEmployee(null);
                                    setSelectedRole("");
                                }}
                            >
                                Add Employee
                            </Button>
                        </Grid>
                    </Grid>



                    <Grid item container xs={10} marginBottom={3}>
                        <Table sx={{ minWidth: 650, bgcolor: "white" }}>
                            <TableHead bgcolor="#5570dc">
                                <TableRow>
                                    <TableCell>NAME</TableCell>
                                    <TableCell>ROLE</TableCell>
                                    <TableCell>ACTIONS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displaySelection.map((employee, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{employee.name}</TableCell>
                                        <TableCell>{employee.role}</TableCell>
                                        <TableCell>
                                            <Button color="error" onClick={() => handleRemoveEmployee(employee.name)}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={12} marginBottom={4}>
                        <Table sx={{ minWidth: 650, bgcolor: "white" }}>
                            <TableHead bgcolor="#5570dc">
                                <TableRow>
                                    <TableCell>FILE TYPE</TableCell>
                                    <TableCell>URL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Text File</TableCell>
                                    <TableCell>
                                        {textFileUrl ? (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => window.open(textFileUrl, "_blank")}
                                            >
                                                View
                                            </Button>
                                        ) : (
                                            "N/A"
                                        )}
                                    </TableCell>

                                </TableRow>
                                {/* Add other files similarly if needed */}
                            </TableBody>
                        </Table>
                    </Grid>


                    <Button variant="contained" type="submit">
                        Update
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
};

export default AudiobookEditPage;
