import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';

const UserSelectAutocomplete = ({ onUserSelect, existingUsers = [], fetchUsersUrl, applyWidth = true }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);

  // Separate API call function
  const fetchUsersAPI = async (searchTerm) => {
    setLoading(true);
    try {
      const response = await axios.post(
        fetchUsersUrl,
        {
          search: searchTerm || '',
          limit: 5,
        }
      );

      if (response.data?.users) {
        const filteredUsers = response.data.users.filter(
          user => !existingUsers.some(existing => existing.id === user.id)
        );
        setOptions(filteredUsers);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useMemo(
    () => debounce((term) => {
      if (term.length >= 2 || term.length === 0) {
        fetchUsersAPI(term);
      }
    }, 500),
    [] // Empty dependency array since we don't want to recreate this function
  );

  // Cleanup effect
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Initial load effect
  useEffect(() => {
    if (open && options.length === 0) {
      fetchUsersAPI('');
    }
  }, [open]);

  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === 'reset') {
      return;
    }

    setInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  return (
    <Autocomplete
      sx={applyWidth ? { width: 300 } : undefined}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={loading}
      value={selectedUser}
      onChange={(event, newValue) => {
        setSelectedUser(newValue);
        if (newValue) {
          onUserSelect({
            id: newValue.id,
            name: newValue.name,
            email: newValue.email,
            isAdmin: newValue.isAdmin
          });
        }
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search users"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;
        return (
          <li key={option.id} {...otherProps}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '4px 0'
            }}>
              <div>
                <div style={{ fontWeight: 500 }}>{option.name}</div>
                <div style={{ fontSize: '0.875rem', color: '#666' }}>
                  {option.email}
                </div>
              </div>
              {option.isAdmin && (
                <div
                  style={{
                    fontSize: '0.75rem',
                    backgroundColor: '#e3f2fd',
                    color: '#1976d2',
                    padding: '2px 6px',
                    borderRadius: '4px',
                  }}
                >
                  Admin
                </div>
              )}
            </div>
          </li>
        );
      }}
      noOptionsText="No users found"
    />
  );
};

export default UserSelectAutocomplete;
