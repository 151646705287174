import React, { useEffect, useRef, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import { Button, Typography, Grid, Slider, IconButton, TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import { PlayArrow, Pause, SkipNext, SkipPrevious, VolumeUp, VolumeOff } from '@mui/icons-material';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classes from "../../Assets/Styles/UnifiedVideoPlayer.module.css";

const AudiobookPlayer = forwardRef(({ dialogues, setPlayingIndex, stateRef, showNumbers, onNumberingChange }, ref) => {
    const sortedDialogues = useMemo(() => {
        return [...dialogues].sort((a, b) => a.sequence - b.sequence);
    }, [dialogues]);

    const totalDuration = useMemo(() => {
        return sortedDialogues.reduce((acc, dialogue) => {
            if (typeof dialogue.audio_duration === 'number') {
                return acc + dialogue.audio_duration;
            } else {
                console.warn(`Dialogue ID ${dialogue.id} is missing 'audio_duration'. Assuming 0.`);
                return acc;
            }
        }, 0);
    }, [sortedDialogues]);

    const audioRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [error, setError] = useState(null);
    const [pauseInputValue, setPauseInputValue] = useState('0.2');
    const [pauseDuration, setPauseDuration] = useState(0.2);
    const [inputTime, setInputTime] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    const pauseTimerRef = useRef(null);
    const cumulativeDurations = useMemo(() => {
        const cumDur = [];
        let sum = 0;
        sortedDialogues.forEach(dialogue => {
            cumDur.push(sum);
            if (typeof dialogue.audio_duration === 'number') {
                sum += dialogue.audio_duration;
            }
        });
        cumDur.push(sum);
        return cumDur;
    }, [sortedDialogues]);

    const playFromIndex = (index) => {
        if (index >= 0 && index < sortedDialogues.length) {
            setCurrentIndex(index);
            setIsPlaying(true);
        }
    };

    useImperativeHandle(ref, () => ({
        playFromIndex,
        pausePlayback,
        resumePlayback,
        isPlaying: () => isPlaying
    }));

    useEffect(() => {
        if (audioRef.current && sortedDialogues.length > 0) {
            const audio = audioRef.current;

            if (audio.src !== sortedDialogues[currentIndex].audio_url) {
                audio.src = sortedDialogues[currentIndex].audio_url || '';
                audio.load();
                console.log(`Loading dialogue ${currentIndex + 1}:`, sortedDialogues[currentIndex].text);
            }

            if (isPlaying) {
                audio.play().catch(err => {
                    console.error('Playback error:', err);
                    setError('Failed to play the audio.');
                    setIsPlaying(false);
                });
            }
        }
    }, [currentIndex, sortedDialogues, isPlaying]);

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        const handleEnded = () => {
            console.log(`Dialogue ${currentIndex + 1} ended.`);
            if (currentIndex < sortedDialogues.length - 1) {
                if (pauseTimerRef.current) {
                    clearTimeout(pauseTimerRef.current);
                }
                pauseTimerRef.current = setTimeout(() => {
                    setCurrentIndex(prev => prev + 1);
                    setIsPlaying(true);
                }, pauseDuration * 1000);
            } else {
                setIsPlaying(false);
                console.log('All dialogues have been played.');
            }
        };

        const handleTimeUpdate = () => {
            const currentTime = audio.currentTime;
            const cumulativeTime = cumulativeDurations[currentIndex] + currentTime;
            setProgress(cumulativeTime);
        };

        const handleError = (e) => {
            console.error('Audio playback error:', e);
            setError('An error occurred during audio playback.');
            setIsPlaying(false);
        };

        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('error', handleError);

        return () => {
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('error', handleError);
            if (pauseTimerRef.current) {
                clearTimeout(pauseTimerRef.current);
            }
        };
    }, [currentIndex, sortedDialogues.length, cumulativeDurations, pauseDuration]);

    useEffect(() => {
        if (setPlayingIndex && sortedDialogues[currentIndex]) {
            setPlayingIndex(sortedDialogues[currentIndex].sequence);
        }
    }, [currentIndex, setPlayingIndex, sortedDialogues]);

    const handlePauseDurationChange = (event) => {
        const value = event.target.value;
        setPauseInputValue(value);

        const numValue = parseFloat(value);
        if (!isNaN(numValue) && numValue >= 0) {
            setPauseDuration(numValue);
        }
    };

    const pausePlayback = () => {
        if (audioRef.current && isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const resumePlayback = () => {
        if (audioRef.current && !isPlaying) {
            audioRef.current.play().catch(err => {
                console.error('Resume playback error:', err);
                setError('Failed to resume audio.');
                setIsPlaying(false);
                if (stateRef) stateRef.current.isPlaying = false;
            });
            setIsPlaying(true);
        }
    };

    const togglePlayPause = () => {
        if (!isPlaying) {
            setIsPlaying(true);
            if (stateRef) stateRef.current.isPlaying = true;
            audioRef.current.play().catch(err => {
                console.error('Playback error:', err);
                setError('Failed to play the audio.');
                setIsPlaying(false);
                if (stateRef) stateRef.current.isPlaying = false;
            });
        } else {
            setIsPlaying(false);
            if (stateRef) stateRef.current.isPlaying = false;
            audioRef.current.pause();
        }
    };

    const skipNext = () => {
        if (currentIndex < sortedDialogues.length - 1) {
            setCurrentIndex(prev => prev + 1);
            setIsPlaying(true);
        }
    };

    const skipPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prev => prev - 1);
            setIsPlaying(true);
        }
    };

    const toggleMute = () => {
        setIsMuted(prev => !prev);
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = isMuted ? 0 : volume;
        }
    }, [volume, isMuted]);

    useEffect(() => {
        if (!isEditing) {
            const timeInSeconds = currentTime / 1000;
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            const milliseconds = Math.floor((timeInSeconds % 1) * 100);

            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(2, '0')}`;
            setInputTime(formattedTime);
        }
    }, [currentTime, isEditing]);

    const progressPercentage = useMemo(() => {
        return totalDuration > 0 ? (progress / totalDuration) * 100 : 0;
    }, [progress, totalDuration]);

    const handleSeek = (event, newValue) => {
        const seekTo = (newValue / 100) * totalDuration;

        let dialogueIndex = sortedDialogues.findIndex((_, index) => {
            return seekTo >= cumulativeDurations[index] && seekTo < cumulativeDurations[index + 1];
        });

        if (dialogueIndex === -1) {
            dialogueIndex = sortedDialogues.length - 1;
        }

        setCurrentIndex(dialogueIndex);

        const newCurrentTime = seekTo - cumulativeDurations[dialogueIndex];
        setProgress(seekTo);

        if (audioRef.current) {
            audioRef.current.currentTime = newCurrentTime;
        }
    };

    const handleSeekCommit = (event, newValue) => {
        const seekTo = (newValue / 100) * totalDuration;

        let dialogueIndex = sortedDialogues.findIndex((_, index) => {
            return seekTo >= cumulativeDurations[index] && seekTo < cumulativeDurations[index + 1];
        });

        if (dialogueIndex === -1) {
            dialogueIndex = sortedDialogues.length - 1;
        }

        setCurrentIndex(dialogueIndex);

        const newCurrentTime = seekTo - cumulativeDurations[dialogueIndex];
        setProgress(seekTo);

        if (audioRef.current) {
            audioRef.current.currentTime = newCurrentTime;

            if (isPlaying) {
                audioRef.current.play().catch(err => {
                    console.error('Playback error during seek:', err);
                    setError('Failed to play the audio.');
                });
            }
        }
    };


    const handleTimeInputChange = (e) => {
        const value = e.target.value;
        setInputTime(value);
        setIsEditing(true);

        // Parse the input time into milliseconds
        const [hours, minutes, seconds, milliseconds] = value.split(/[:.]/).map(Number);
        const newTimeInSeconds =
            (hours || 0) * 3600 +
            (minutes || 0) * 60 +
            (seconds || 0) +
            (milliseconds || 0) / 100;

        // If we have valid numbers and time is within range, seek
        if (!isNaN(newTimeInSeconds) && newTimeInSeconds >= 0 && newTimeInSeconds <= totalDuration) {
            // Find the dialogue that contains this timestamp
            let targetIndex = 0;
            let accumulatedDuration = 0;

            for (let i = 0; i < sortedDialogues.length; i++) {
                const nextAccumulatedDuration = accumulatedDuration + sortedDialogues[i].audio_duration;
                if (newTimeInSeconds >= accumulatedDuration && newTimeInSeconds < nextAccumulatedDuration) {
                    targetIndex = i;
                    break;
                }
                accumulatedDuration = nextAccumulatedDuration;
            }

            // Set the current index and time
            setCurrentIndex(targetIndex);
            if (audioRef.current) {
                // Calculate the offset within the current dialogue
                const offsetInDialogue = newTimeInSeconds - cumulativeDurations[targetIndex];
                audioRef.current.currentTime = offsetInDialogue;
                setProgress(newTimeInSeconds);
                if (isPlaying) {
                    audioRef.current.play().catch(err => {
                        console.error('Playback error during time jump:', err);
                        setError('Failed to play the audio.');
                    });
                }
            }
        }
    };

    const scrollToDialogue = (index) => {
        const dialogueElements = document.querySelectorAll('[data-sequence]');
        const targetElement = Array.from(dialogueElements).find(
            el => parseInt(el.getAttribute('data-sequence')) === sortedDialogues[index].sequence
        );

        if (targetElement) {
            // Calculate position to scroll to
            const offset = 200; // Adjust this value based on your layout
            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            // Smooth scroll to the dialogue
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            const [hours, minutes, seconds, milliseconds] = inputTime.split(/[:.]/).map(Number);
            const newTimeInSeconds =
                (hours || 0) * 3600 +
                (minutes || 0) * 60 +
                (seconds || 0) +
                (milliseconds || 0) / 100;

            if (!isNaN(newTimeInSeconds) && newTimeInSeconds >= 0 && newTimeInSeconds <= totalDuration) {
                let targetIndex = 0;
                let accumulatedDuration = 0;

                for (let i = 0; i < sortedDialogues.length; i++) {
                    const nextAccumulatedDuration = accumulatedDuration + sortedDialogues[i].audio_duration;
                    if (newTimeInSeconds >= accumulatedDuration && newTimeInSeconds < nextAccumulatedDuration) {
                        targetIndex = i;
                        break;
                    }
                    accumulatedDuration = nextAccumulatedDuration;
                }

                // Set the current index and time
                setCurrentIndex(targetIndex);
                if (audioRef.current) {
                    const offsetInDialogue = newTimeInSeconds - cumulativeDurations[targetIndex];
                    audioRef.current.currentTime = offsetInDialogue;
                    setProgress(newTimeInSeconds);
                    if (isPlaying) {
                        audioRef.current.play().catch(err => {
                            console.error('Playback error during time jump:', err);
                            setError('Failed to play the audio.');
                        });
                    }
                }

                // Scroll to the dialogue after a short delay
                setTimeout(() => scrollToDialogue(targetIndex), 100);
            }

            setIsEditing(false);
        }
    };

    // Handle blur event to reset editing state
    const handleBlur = () => {
        setIsEditing(false);
    };

    return (
        <Grid container direction="column" alignItems="center" spacing={2}>
            {sortedDialogues.length > 0 && (
                <>
                    <Grid item style={{ width: '80%' }}>
                        <Slider
                            value={totalDuration > 0 ? (progress / totalDuration) * 100 : 0}
                            onChange={handleSeek}
                            onChangeCommitted={handleSeekCommit}
                            aria-labelledby="audiobook-progress-slider"
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => {
                                const timeInSeconds = (value / 100) * totalDuration;
                                return formatTime(timeInSeconds);
                            }}
                            min={0}
                            max={100}
                            step={0.1}
                        />
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="caption">
                                    {formatTime(progress)} / {formatTime(totalDuration)}
                                </Typography>
                            </Grid>
                            <Grid px={1}>-</Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    {sortedDialogues.length > 0
                                        ? `(${currentIndex + 1}/${sortedDialogues.length})`
                                        : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}

            {sortedDialogues.length > 0 && (
                <>
                    <Grid item container alignItems="center" spacing={2} justifyContent="center">
                        <Grid item>
                            <IconButton onClick={skipPrevious} disabled={currentIndex === 0}>
                                <SkipPrevious />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={togglePlayPause}
                                startIcon={isPlaying ? <Pause /> : <PlayArrow />}
                            >
                                {isPlaying ? 'Pause' : 'Play'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={skipNext} disabled={currentIndex === sortedDialogues.length - 1}>
                                <SkipNext />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={toggleMute}>
                                {isMuted ? <VolumeOff /> : <VolumeUp />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <Slider
                                value={volume}
                                onChange={(e, newValue) => setVolume(newValue)}
                                aria-labelledby="volume-slider"
                                min={0}
                                max={1}
                                step={0.05}
                                valueLabelDisplay="auto"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Pause Between Dialogues"
                                type="number"
                                size="small"
                                value={pauseInputValue}
                                onChange={handlePauseDurationChange}
                                inputProps={{
                                    step: "0.1"
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">s</InputAdornment>,
                                }}
                                sx={{ width: '150px' }}
                            />
                        </Grid>
                        <Grid item container alignItems="center" gap={0.5} sx={{ maxWidth: '135px' }}>
                            <InputMask
                                mask="99:99:99.99"
                                value={inputTime}
                                onChange={handleTimeInputChange}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}  // Add this line
                                className={classes["AudioWorkPlaceTimelineEditTranslation"]}
                                placeholder="00:00:00.00"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showNumbers}
                                        onChange={(e) => onNumberingChange(e.target.checked)}
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Numbers"
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            <audio ref={audioRef} />
        </Grid>
    );
});

const formatTime = (seconds, decimalPlaces = 0) => {
    if (isNaN(seconds)) return '00:00:00';
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = (seconds % 60).toFixed(decimalPlaces);
    return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2 + decimalPlaces, '0')}`;
};

AudiobookPlayer.propTypes = {
    dialogues: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            sequence: PropTypes.number.isRequired,
            audio_url: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            audio_duration: PropTypes.number.isRequired,
        })
    ).isRequired,
    setPlayingIndex: PropTypes.func.isRequired,
    stateRef: PropTypes.shape({
        current: PropTypes.object.isRequired
    }),
    onNumberingChange: PropTypes.func.isRequired,
    showNumbers: PropTypes.bool.isRequired,
};

export default AudiobookPlayer;