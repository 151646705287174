import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,

} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { storage } from "../config/firebase";
import {
  CREATE_PROJECT,
  CREATE_AUDIOBOOK,
  UPLOAD_AUDIOBOOK_TEXT,
  DEMUCS_SPLIT,
  FACE_DETECTOR,
  GET_USERS,
  REQUEST_POLL_TRANSCRIBE,
  TRANSCRIBE,
  UPLOAD_AUDIO_DRIVE,
  UPLOAD_AUDIO_DROPBOX,
  UPLOAD_VIDEO_DRIVE,
  UPLOAD_VIDEO_DROPBOX,
} from "../MProjectConst";
import { languagesCode } from "../Data/LanguagesCode.js";
import { getDownloadURL, ref, uploadBytesResumable, uploadBytes } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  formatInputs,
  getLanguageName,
  getTaskName,
} from "../utils/generalFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import SRTSubtitleParser from "../Components/ProjectCreationComponents/SRTSubtitleParser.js";
import classes from "../Assets/Styles/Global.module.css";
import GlobalButton from "../Components/Buttons/GlobalButton.js";
import TaskButton from "../Components/Buttons/TaskButton.js";
import { v4 as uuidv4 } from 'uuid';

import UserSelectAutocomplete from "../Components/UserSelectAutoComplete/index.js";

const ProjectCreationScreen = () => {

  const navigate = useNavigate();

  const [videoURL, setVideoURL] = useState(null);
  const [srtURL, setSrtURL] = useState();

  const [employes, setEmployes] = useState([]);
  const [projectName, setProjectName] = useState();
  const [projectOrginalLang, setProjectOrginalLang] =
    useState("Original Language");
  const [projectFinalLang, setProjectFinalLang] = useState("Final Languages");
  const [projectFinalLangs, setProjectFinalLangs] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("select an to add user");
  const [selectRole, setSelectRole] = useState(
    "select the role of the employee"
  );
  const [displaySelection, setDisplaySelection] = useState([]);
  const [upLoader, setUpLoader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [audioProgress, setAudioProgress] = useState(0);
  const [jsonSrtProgress, setJsonSrtProgress] = useState(0);

  const [uploadOptions, setUploadOption] = useState("videoAndAudio");
  const [videoOptions, setVideoOptions] = useState({
    choosenType: "file",
    drive: {
      error: false,
      url: "",
    },
    dropbox: {
      error: false,
      url: "",
    },
    file: {
      error: false,
      uploaded: false,
      filePath: "",
    },
  });
  const [srtData, setSrtData] = useState(false);

  const [videoUploadButton, setVideoUploadButton] = useState(false);

  const [showAudioAddingOption, setShowAudioAddingOption] = useState(false);
  const [showAddingSrtFileOption, setShowAddingSrtFileOption] = useState(false);

  const [channelType, setChannelType] = useState("");
  const [audioType, setAudioType] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [selectUrlFile, setSelectUrlFile] = useState();

  const [uploadedAudioTypes, setUploadedAudioTypes] = useState([]);

  const [facesToggle, setFacesToggle] = useState(false);
  const originalVideoDetails = { width: 600, height: 337, videoFPS: 25 };
  const [selectedUserObj, setSelectedUserObj] = useState(null); // Add this state

  const [projectType, setProjectType] = useState('video');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [txtFile, setTxtFile] = useState(null);
  const [txtFileContent, setTxtFileContent] = useState('');
  const [txtFileProgress, setTxtFileProgress] = useState(0);
  const [txtFileData, setTxtFileData] = useState(null);
  const [provider, voiceId] = selectedVoice.split(':');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employes = await axios.post(GET_USERS);
        setEmployes(employes.data.users);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const getUserId = () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      return JSON.parse(userInfo).id;
    }
    return null;
  };

  // submission api
  const handleSubmitCreation = async (e) => {
    // In the same component
    if (projectType === 'audiobook') {
      if (!projectName) {
        toast.error("Project name is required");
        return;
      }
      if (!txtFileData) {
        toast.error("Please upload a text file");
        return;
      }
      if (!selectedVoice) {
        toast.error("Please select a voice");
        return;
      }
      if (projectFinalLangs.length === 0) {
        toast.error("Please select at least one target language");
        return;
      }

      setUpLoader(true);

      try {
        // Get user ID from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (!userInfo || !userInfo.id) {
          toast.error("User authentication required");
          return;
        }

        // Generate version filename
        const versionFileName = `${txtFile.name.split('.')[0]}_${uuidv4()}.txt`;

        // Create project and upload text content in one call
        const response = await axios.post(CREATE_AUDIOBOOK, {
          projectName,
          finalLanguages: projectFinalLangs,
          voiceName: getVoiceName(selectedVoice),
          voiceUid: voiceId,
          voiceProvider: provider,
          voiceVersion: "1.0",
          emotion: "neutral",
          employees: displaySelection,
          versionFileName,
          textContent: txtFileData,
          userId: userInfo.id
        });

        if (response.data.success) {
          toast.success("Audiobook project created successfully");
          navigate("/adminlanding");
        }
      } catch (error) {
        console.error("Error creating audiobook project:", error);
        toast.error(error.response?.data?.message || "Error creating audiobook project");
      } finally {
        setUpLoader(false);
      }
      return;
    }

    // Existing video project submission logic
    const officalDialogueAudioUrl = uploadedAudioTypes.find(
      (audio) => audio.audioType === "dialogue"
    );

    if (!videoURL || !projectFinalLangs) {
      toast.error("you should upload the video first!!");
      return;
    }

    if (!officalDialogueAudioUrl) {
      toast.error("Project can't be created without any dialogue audio!!");
      return;
    }

    setUpLoader(true);

    try {
      const response = await axios.post(CREATE_PROJECT, {
        employes: displaySelection,
        name: projectName,
        orginalLang: projectOrginalLang,
        finalLang: projectFinalLangs,
        videoUrl: videoURL,
        dialogueAudioUrl: officalDialogueAudioUrl?.url
          ? officalDialogueAudioUrl?.url
          : "",
        uploadedAudioTypes: uploadedAudioTypes,
        originalVideoDetails: originalVideoDetails,
      });

      console.log("Project has been added");
      toast.success("Project has been added");

      if (officalDialogueAudioUrl.url && uploadOptions === "videoAndAudio") {
        axios.post(TRANSCRIBE, {
          url: officalDialogueAudioUrl.url,
          projectId: response.data.projectId,
          language: projectOrginalLang,
        });
      }

      if (facesToggle && videoOptions.file.uploaded) {
        axios.post(FACE_DETECTOR, {
          videoUrl: videoURL,
          projectName: projectName,
        });
      }

      navigate("/adminlanding");
      setUpLoader(false);
    } catch (error) {
      console.log(error);
      setUpLoader(false);
    }
  };

  //upload srt file as a json file
  const handleJsonUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }

    const jsonData = JSON.stringify(srtData);

    const blob = new Blob([jsonData], { type: "application/json" });

    const fileName = `${projectName}/Srt-${Date.now()}.json`;

    const storageRef = ref(storage, fileName);

    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setJsonSrtProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          toast.success("Srt JSON file has been uploaded successfully");
          setSrtURL(URL);
        });
      }
    );
  };

  // video file uploading
  const handelVideoUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }

    toast.info("Video is being uploaded wait for it");

    const videoFile = videoOptions.file.filePath;
    const fileName = `${projectName}/${videoFile.name}`;

    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, videoFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setVideoProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (URL) => {
          if (uploadOptions === "onlyVideo") {
            try {
              const demusResponse = axios.post(DEMUCS_SPLIT, {
                projectName: projectName,
                url: URL,
              });
              // console.log(demusResponse.data.task_id);
            } catch (er) {
              console.log(er);
            }
          }

          toast.success("Video his been uploaded successfully");
          setVideoOptions((prevMode) => ({
            ...prevMode,
            file: {
              ...prevMode.file,
              filePath: "",
              uploaded: true,
            },
          }));

          setVideoURL(URL);
        });
      }
    );
  };

  // video google drive url uploading
  const handleVideoUploadDrive = async (e) => {
    toast.info("Video is getting ready to be uploaded wait a bit!");
    const videoDriveUrl = videoOptions.drive.url;

    if (!videoDriveUrl) {
      toast.error("you should fill the video drive url first");

      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }

    setVideoUploadButton(true);
    try {
      const response = await axios.post(UPLOAD_VIDEO_DRIVE, {
        projectName: projectName,
        url: videoDriveUrl,
        demucs: uploadOptions === "onlyVideo" ? true : false,
      });

      const videoUrl = `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${response.data.videoPath}`;
      // console.log(videoUrl);
      setVideoURL(videoUrl);
      toast.success("Video is being uploaded");
    } catch (error) {
      console.log(error);
      toast.error("Error while uploading the video");

      setVideoUploadButton(false);
    }
  };

  // video drop box url uploading
  const handleVideoUploadDropbox = async (e) => {
    toast.info("Video is getting ready to be uploaded wait a bit!");
    const videoDropboxUrl = videoOptions.dropbox.url;

    if (!videoDropboxUrl) {
      toast.error("you should fill the video dropbox url first");

      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");

      return;
    }

    setVideoUploadButton(true);

    try {
      const response = await axios.post(UPLOAD_VIDEO_DROPBOX, {
        projectName: projectName,
        url: videoDropboxUrl,
        demucs: uploadOptions === "onlyVideo" ? true : false,
      });
      // console.log(response.data);
      const videoUrl = `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${response.data.videoPath}`;
      setVideoURL(videoUrl);
      toast.success("Video is being uploaded");
    } catch (error) {
      console.log(error);
      setVideoUploadButton(false);
    }
  };

  // audio file uploading
  const handleDirectAudioUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }
    toast.info("file is being uploaded wait for it");

    const audioFile = selectUrlFile;
    const fileName = `${projectName}/${projectName}_${audioType}_${channelType}.wav`;
    // const fileName = `${projectName}/${audioFile.name}_${audioType}_${channelType}`;

    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, audioFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setAudioProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          toast.success(`${audioType} file his been uploaded successfully`);

          setUploadedAudioTypes((prev) => [
            ...prev,
            {
              channelType: channelType,
              audioType: audioType,
              uploadType: uploadType,
              url: URL,
            },
          ]);

          setChannelType("");
          setAudioType("");
          setUploadType("");
          setSelectUrlFile("");
          setAudioProgress(0);
          setShowAudioAddingOption(false);
        });
      }
    );
  };
  // audio  url uploading
  const handleUrlAudioUpload = async (uploadMethod) => {
    if (!selectUrlFile) {
      toast.error("you should fill the url box");
      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }
    if (!channelType) {
      toast.error("choose a channel type");
      return;
    }

    toast.info("Audio url is uploading, wait for it");

    try {
      let response;
      if (uploadMethod === "googleDrive") {
        response = await axios.post(UPLOAD_AUDIO_DRIVE, {
          projectName: projectName,
          url: selectUrlFile,
          type: `${audioType}_${channelType}`,
        });
      }

      if (uploadMethod === "dropbox") {
        response = await axios.post(UPLOAD_AUDIO_DROPBOX, {
          projectName: projectName,
          url: selectUrlFile,
          type: `${audioType}_${channelType}`,
        });
      }

      setUploadedAudioTypes((prev) => [
        ...prev,
        {
          channelType: channelType,
          audioType: audioType,
          uploadType: uploadType,
          url: response.data.url,
        },
      ]);

      setChannelType("");
      setAudioType("");
      setUploadType("");
      setSelectUrlFile("");
      setShowAudioAddingOption(false);
      // console.log(response.data.url);
      // setAudioUrl(response.data.url);
      toast.success("Audio dialogue his been uploaded successfully");

      setUpLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Error while uploading Audio dialogue");
      setUpLoader(false);
    }
  };

  function isValidUrl(url) {
    // Regular expression for URL validation
    if (!channelType) {
      return false;
    }
    if (!audioType) {
      return false;
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the input string against the regex
    return urlRegex.test(url);
  }

  function isValidUrlForVideo(url) {
    // Regular expression for URL validation

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the input string against the regex
    return urlRegex.test(url);
  }

  const handleRemoveUploadedFile = (url) => {
    // console.log(url);
    const newItems = uploadedAudioTypes.filter((item) => item.url !== url);
    console.log(newItems);
    setUploadedAudioTypes(newItems);
  };

  const handleRemoveEmployes = (name) => {
    const newItems = displaySelection.filter((item) => item.name !== name);
    setDisplaySelection(newItems);
  };

  const handleRemoveLanguage = (code) => {
    const newItems = projectFinalLangs.filter(
      (languageCode) => languageCode !== code
    );
    setProjectFinalLangs(newItems);
  };

  const handleProjectTypeChange = (event) => {
    const newType = event.target.value;
    setProjectType(newType);

    // Reset relevant states when switching to audiobook
    if (newType === 'audiobook') {
      setProjectOrginalLang("Original Language");
      setProjectFinalLangs([]);
      setUploadOption("videoAndAudio");
    }
  };

  const handleTxtFileUpload = () => {
    if (!projectName) {
      toast.error("You should fill the project name first");
      return;
    }

    if (!txtFile) {
      toast.error("Please select a TXT file first");
      return;
    }

    toast.info("TXT file is being uploaded, please wait");

    const fileName = `${projectName}/text/${txtFile.name}`;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, txtFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setTxtFileProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        toast.error("Error uploading TXT file");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          toast.success("TXT file has been uploaded successfully");
          setTxtFileContent(url);
        });
      }
    );
  };

  const handleTxtFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setTxtFile(file);

    // Read the file content
    const reader = new FileReader();
    reader.onload = (e) => {
      setTxtFileData(e.target.result);
      toast.success("Text file loaded successfully");
    };
    reader.onerror = () => {
      toast.error("Error reading text file");
    };
    reader.readAsText(file);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      {/* {console.log(uploadedAudioTypes)} */}
      {/* {console.log(videoOptions.file.uploaded)} */}

      <Loader Load={upLoader} />
      <ToastContainer position="top-center" theme="dark" />

      <Grid item container xs={9} marginTop={7} direction="column">
        <Grid>
          <Grid item marginBottom={5} xs={3} className={classes["header"]}>
            Create Project
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            <TextField
              width="100%"
              type="text"
              value={projectName}
              label="Project Name"
              onChange={(e) => setProjectName(formatInputs(e.target.value))}
            />
          </Grid>

          <Grid item xs={12} mb={3}>
            <div className="mb-6">
              <Grid item className={classes["midHeader"]}>
                Project Type
              </Grid>
              <RadioGroup
                row
                value={projectType}
                onChange={handleProjectTypeChange}
              >
                <FormControlLabel value="video" control={<Radio />} label="Video" />
                <FormControlLabel value="audiobook" control={<Radio />} label="Audiobook (TTS-Only)" />
              </RadioGroup>
            </div>
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            {projectType !== 'audiobook' && (
              <FormControl className="min-w-[200px]">
                <Select
                  value={projectOrginalLang}
                  onChange={(e) => setProjectOrginalLang(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="Original Language" disabled>
                    Original Language
                  </MenuItem>
                  {languagesCode.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            marginBottom={3}
            alignItems="center"
            direction="row"
            gap={5}
          >
            <Grid item>
              <FormControl style={{ width: "220px" }}>
                {/* <InputLabel>Final Languages</InputLabel> */}
                <Select
                  value={projectFinalLang}
                  onChange={(e) => {
                    // setProjectFinalLang(e.target.value);
                    setProjectFinalLangs([
                      ...projectFinalLangs,
                      e.target.value,
                    ]);
                    setProjectFinalLang("");
                  }}
                // label={"Select Final Languages"}
                >
                  <MenuItem value="Final Languages" disabled={true}>
                    Final Languages
                  </MenuItem>

                  {languagesCode.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <Grid container gap={1}>
                {projectFinalLangs.map((language, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      bgcolor: "lightblue",
                      minWidth: "70px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid item marginLeft={1}>
                      {getLanguageName(language)}
                    </Grid>
                    <Grid item marginLeft={2}>
                      <IconButton
                        onClick={() => handleRemoveLanguage(language)}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* {console.log(general Options)} */}

          {projectType === 'video' ? (
            <>
              <Grid
                container
                direction="column"
                spacing={2}
                marginBottom={5}
                marginTop={5}
              >
                <Grid item className={classes["midHeader"]}>
                  Uploading Option
                </Grid>
                <Grid item>
                  <Grid
                    container
                    onChange={(e) => {
                      if (!projectName) {
                        toast.error("you should fill the project name first");
                        return;
                      }
                      if (e.target.value === "onlyVideo") {
                        setUploadedAudioTypes([
                          {
                            channelType: "right",
                            audioType: "dialogue",
                            uploadType: "demucs",
                            url: `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${projectName}/demucs_audios/demucs/combined_vocals.mp3`,
                          },
                          {
                            channelType: "right",
                            audioType: "music",
                            uploadType: "demucs",
                            url: `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${projectName}/demucs_audios/demucs/combined_no_vocals.mp3`,
                          },
                        ]);
                      } else {
                        setUploadedAudioTypes([]);
                      }

                      setUploadOption(e.target.value);
                    }}
                    gap={4}
                    marginBottom={3}
                  >
                    <Grid item>
                      <input
                        type="radio"
                        value="videoAndAudio"
                        checked={uploadOptions === "videoAndAudio"}
                        readOnly
                      />
                      <label htmlFor="videoAndAudio">Video & Audios</label>
                    </Grid>

                    <Grid item>
                      <input
                        type="radio"
                        value="onlyVideo"
                        checked={uploadOptions === "onlyVideo"}
                        readOnly
                      />
                      <label htmlFor="onlyVideo"> Only Video</label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="column"
                spacing={2}
                marginBottom={5}
                marginTop={5}
              >
                <Grid item className={classes["midHeader"]}>
                  Video Options
                </Grid>
                <Grid item>
                  <Grid
                    container
                    onChange={(e) =>
                      setVideoOptions({
                        choosenType: e.target.value,
                        drive: {
                          error: false,
                          url: "",
                        },
                        dropbox: {
                          error: false,
                          url: "",
                        },
                        file: {
                          error: false,
                          filePath: "",
                        },
                      })
                    }
                    gap={4}
                    marginBottom={3}
                  >
                    <Grid item>
                      <input
                        type="radio"
                        value="file"
                        checked={videoOptions.choosenType === "file"}
                        readOnly
                      />
                      <label htmlFor="file">Upload File</label>
                    </Grid>

                    <Grid item>
                      <input
                        type="radio"
                        value="dropbox"
                        checked={videoOptions.choosenType === "dropbox"}
                        readOnly
                      />
                      <label htmlFor="dropbox">Dropbox URL</label>
                    </Grid>

                    <Grid>
                      <input
                        type="radio"
                        value="google-drive"
                        checked={videoOptions.choosenType === "google-drive"}
                        readOnly
                      />
                      <label htmlFor="google-drive">Google Drive URL</label>
                    </Grid>
                  </Grid>
                  {videoOptions.choosenType === "file" && (
                    <Grid item container direction="row">
                      <Grid item xs={2.2}>
                        <input
                          className={classes["file-upload-button"]}
                          type="file"
                          onChange={(e) => {
                            setVideoOptions((prevMode) => ({
                              ...prevMode,
                              file: {
                                ...prevMode.file,
                                filePath: e.target.files[0],
                              },
                            }));
                            setVideoURL(null);
                            setVideoProgress(0);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TaskButton
                          // disabled={videoUploadButton}
                          onClick={() => handelVideoUpload()}
                          variant="outlined"
                        >
                          Upload
                        </TaskButton>
                        {" " + Math.floor(videoProgress)}%
                        {videoProgress === 100 ? (
                          <> its has been uploaded successfully</>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {videoOptions.choosenType === "dropbox" && (
                    <Grid item container gap={3}>
                      <Grid item xs={2}>
                        <input
                          className={classes["input"]}
                          type="text"
                          placeholder="Dropbox URL"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setVideoOptions((prevMode) => ({
                              ...prevMode,
                              dropbox: {
                                ...prevMode.file,
                                url: e.target.value,
                                error: isValidUrlForVideo(e.target.value),
                              },
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TaskButton
                          disabled={videoUploadButton}
                          onClick={() => handleVideoUploadDropbox()}
                        >
                          Upload
                        </TaskButton>
                      </Grid>
                      {videoURL && videoOptions.dropbox.url ? (
                        <> link has been submitted successfully</>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                  {videoOptions.choosenType === "google-drive" && (
                    <Grid item container gap={3}>
                      <Grid item xs={2}>
                        <input
                          style={{ width: "100%" }}
                          className={classes["input"]}
                          type="text"
                          placeholder="Google Drive URL"
                          onChange={(e) => {
                            setVideoOptions((prevMode) => ({
                              ...prevMode,
                              drive: {
                                ...prevMode.file,
                                url: e.target.value,
                                error: isValidUrlForVideo(e.target.value),
                              },
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TaskButton
                          disabled={videoUploadButton}
                          onClick={() => handleVideoUploadDrive()}
                        >
                          Upload
                        </TaskButton>
                      </Grid>
                      {videoURL && videoOptions.drive.url ? (
                        <> link has been submitted successfully</>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}

                  {/* {videoURL && <>Url: {videoURL}</>} */}
                </Grid>
              </Grid>

              {uploadOptions === "videoAndAudio" && (
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  marginBottom={15}
                  marginTop={5}
                >
                  <Grid item className={classes["midHeader"]}>
                    Audio Options
                  </Grid>

                  <Grid item xs={12} container direction="row" gap={4}>
                    <Grid item xs={1}>
                      <TaskButton onClick={() => setShowAudioAddingOption(true)}>
                        Add Audio
                      </TaskButton>
                    </Grid>
                    {showAudioAddingOption && (
                      <>
                        <Grid item xs={1}>
                          <Grid item>
                            <select
                              className={classes["selector"]}
                              value={channelType}
                              onChange={(e) => {
                                setChannelType(e.target.value);
                              }}
                            >
                              <option value="">channels</option>
                              <option value="right">right</option>
                              <option value="left">left</option>
                              <option value="multi-channel">multi-channel</option>
                            </select>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} marginRight={8}>
                          <Grid item>
                            <select
                              className={classes["selector"]}
                              value={audioType}
                              onChange={(e) => {
                                setAudioType(e.target.value);
                              }}
                            >
                              <option value="">Type</option>
                              <option value="dialogue">Dialogue</option>
                              <option value="music">Music</option>
                              <option value="effect">Effect</option>
                              <option value="musicEffects">Music & Effects</option>
                              <option value="dialogueMusicEffect">
                                Dialogue, Music, Effects
                              </option>
                            </select>
                          </Grid>
                        </Grid>
                        <Grid item container xs={6} direction="row">
                          <Grid item xs={3.7}>
                            <select
                              className={classes["selector"]}
                              value={uploadType}
                              onChange={(e) => {
                                setUploadType(e.target.value);
                              }}
                            >
                              <option value="">Upload Method</option>
                              <option value="directUpload">Direct Upload</option>
                              <option value="googleDrive">Google Drive</option>
                              <option value="dropbox">Dropbox</option>
                            </select>
                          </Grid>
                          <Grid item xs={8}>
                            {uploadType === "directUpload" && (
                              <Grid item container direction="row">
                                <Grid item xs={6}>
                                  <input
                                    className={classes["file-upload-button"]}
                                    type="file"
                                    onChange={(e) => {
                                      setSelectUrlFile(e.target.files[0]);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TaskButton
                                    disabled={
                                      !selectUrlFile?.type ||
                                      !channelType ||
                                      audioType === ""
                                    }
                                    onClick={() => handleDirectAudioUpload()}
                                  >
                                    Upload
                                  </TaskButton>
                                  {" " + Math.floor(audioProgress)}%
                                  {audioProgress === 100 ? (
                                    <> its has been uploaded successfully</>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            {uploadType === "googleDrive" && (
                              <Grid item container direction="row">
                                <Grid item xs={6}>
                                  <input
                                    className={classes["input"]}
                                    type="text"
                                    placeholder="Google Drive URL"
                                    onChange={(e) => {
                                      setSelectUrlFile(e.target.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TaskButton
                                    disabled={!isValidUrl(selectUrlFile)}
                                    onClick={() =>
                                      handleUrlAudioUpload("googleDrive")
                                    }
                                  >
                                    Upload
                                  </TaskButton>
                                </Grid>
                              </Grid>
                            )}
                            {uploadType === "dropbox" && (
                              <Grid item container direction="row">
                                <Grid item xs={6}>
                                  <input
                                    className={classes["input"]}
                                    type="text"
                                    placeholder="Dropbox URL"
                                    onChange={(e) => {
                                      setSelectUrlFile(e.target.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TaskButton
                                    disabled={!isValidUrl(selectUrlFile)}
                                    onClick={() => handleUrlAudioUpload("dropbox")}
                                  >
                                    Upload
                                  </TaskButton>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {uploadedAudioTypes.length > 0 && (
                    <Grid item marginTop={8}>
                      {/* <Grid item>Uploaded file</Grid> */}
                      <table
                        border="1"
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Channel Type</th>
                            <th>Audio Type</th>
                            <th>Upload Type</th>
                            <th>URL</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedAudioTypes.map((audio, index) => (
                            <tr key={index}>
                              <td>{audio.channelType}</td>
                              <td>{audio.audioType}</td>
                              <td>{audio.uploadType}</td>
                              <td>
                                <a
                                  href={audio.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {audio.url}
                                </a>
                              </td>
                              <td>
                                <Button
                                  color="error"
                                  onClick={() =>
                                    handleRemoveUploadedFile(audio.url)
                                  }
                                >
                                  delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  )}
                </Grid>
              )}

              {/* {console.log(Srt method)} */}

              <Grid
                container
                direction="column"
                spacing={2}
                marginBottom={15}
                marginTop={5}
              >
                <Grid item className={classes["midHeader"]}>
                  Transcription Options
                </Grid>

                <Grid item xs={12} container direction="row" gap={4}>
                  <Grid item xs={1}>
                    <TaskButton onClick={() => setShowAddingSrtFileOption(true)}>
                      Add SRT file
                    </TaskButton>
                  </Grid>
                  {showAddingSrtFileOption && (
                    <Grid item container direction="row" xs={10}>
                      <Grid item xs={2.7}>
                        <SRTSubtitleParser setSrtData={setSrtData} />
                      </Grid>
                      <Grid item xs={2}>
                        <TaskButton
                          disabled={!srtData}
                          onClick={() => handleJsonUpload()}
                          variant="outlined"
                        >
                          Upload
                        </TaskButton>
                        {" " + Math.floor(jsonSrtProgress)}%
                        {jsonSrtProgress === 100 ? (
                          <> its has been uploaded successfully</>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* {console.log(Dubbing options)} */}

              <Grid
                container
                direction="column"
                spacing={2}
                marginBottom={15}
                marginTop={5}
              >
                <Grid item className={classes["midHeader"]}>
                  Dubbing Options
                </Grid>

                <Grid item xs={12} container direction="column" gap={1}>
                  <Grid item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={facesToggle}
                        onChange={(e) => setFacesToggle(e.target.checked)}
                        readOnly
                      />
                      Lip Sync
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {/* Audiobook Specific Sections */}
              <Grid item xs={12} mb={4}>
                <div className="mb-6">
                  <Grid item className={classes["midHeader"]}>
                    Voice Selection
                  </Grid>
                  <FormControl className="min-w-[200px]">
                    <Select
                      value={selectedVoice}
                      onChange={(e) => {
                        setSelectedVoice(e.target.value);
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Voice</MenuItem>
                      {/* ElevenLabs Voices Section */}
                      <MenuItem disabled style={{ fontWeight: 'bold' }}>ElevenLabs Voices</MenuItem>
                      {/* Males - ElevenLabs */}
                      <MenuItem value="elevenlabs:cjDX3gheKRFt01mtnlgO">Marcus (M)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:KSe5Ky8JOOa2PsUdz0qx">Alex (M)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:kOs9fYMiSchRrS2JIwha">Neal (M)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:EiNlNiXeDU1pqqOPrYMO">John (M)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:uju3wxzG5OhpWcoi3SMy">Michael (M)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:NFG5qt843uXKj4pFvR7C">Adam (M)(ElevenLabs)</MenuItem>
                      {/* Females - ElevenLabs */}
                      <MenuItem value="elevenlabs:m6T9tbctbKAnYvdN4nrj">Bria (F)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:lxYfHSkYm1EzQzGhdbfc">Jessica (F)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:U4QF22fMv0qiB9yCLXKL">Maya (F)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:i4CzbCVWoqvD0P1QJCUL">Ivy (F)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:5l5f8iK3YPeGga21rQIX">Adeline (F)(ElevenLabs)</MenuItem>
                      <MenuItem value="elevenlabs:rCmVtv8cYU60uhlsOo1M">Ana (F)(ElevenLabs)</MenuItem>

                      {/* Custom Model Voices Section */}
                      <MenuItem disabled style={{ fontWeight: 'bold', marginTop: '10px' }}>Custom Model Voices</MenuItem>
                      {/* Males - Custom Model */}
                      <MenuItem value="custom:Male_audiobook_1">James (M)(Custom)</MenuItem>
                      <MenuItem value="custom:Male_audiobook_2">Robert (M)(Custom)</MenuItem>
                      <MenuItem value="custom:Male_audiobook_3">William (M)(Custom)</MenuItem>
                      {/* Females - Custom Model */}
                      <MenuItem value="custom:Female_audiobook_1">Emma (F)(Custom)</MenuItem>
                      <MenuItem value="custom:Female_audiobook_2">Sophia (F)(Custom)</MenuItem>
                      <MenuItem value="custom:Female_audiobook_3">Olivia (F)(Custom)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>


              {/* Text File Upload Section for Audiobook */}
              {/* Text File Upload Section for Audiobook */}
              <Grid item xs={12} mb={4}>
                <div className="mb-6">
                  <Grid item className={classes["midHeader"]}>
                    Upload Text
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <input
                        type="file"
                        accept=".txt"
                        onChange={handleTxtFileSelect}
                        className={classes["file-upload-button"]}
                      />
                    </Grid>
                  </Grid>
                  {txtFile && txtFileData && (
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12}>
                        <div className={classes["midHeader"]}>Selected Text File</div>
                        <div className="text-gray-600">
                          File name: {txtFile.name}
                          <br />
                          Size: {(txtFile.size / 1024).toFixed(2)} KB
                          <br />
                          Preview (first 200 characters):
                          <br />
                          <div className="bg-gray-100 p-2 mt-1 rounded">
                            {txtFileData.slice(0, 200)}...
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            </>
          )}

          {/* {console.log(audiomethod)} */}

          <Grid
            item
            container
            xs={10}
            marginBottom={3}
            gap={3}
            alignItems="center"
            direction="row"
          >
            <Grid item className={classes["midHeader"]} marginBottom={1.5}>
              Project Members
            </Grid>
            <Grid item container alignItems="center" direction="row" gap={3}>
              <UserSelectAutocomplete
                onUserSelect={(user) => {
                  setSelectEmployee(user.name);
                  setSelectedUserObj(user);
                }}
                existingUsers={displaySelection}
                fetchUsersUrl={GET_USERS}
              />
              <Grid item>
                <Select
                  value={selectRole}
                  multiline
                  onChange={(event) => setSelectRole(event.target.value)}
                >
                  <MenuItem value="select the role of the employee" disabled>
                    select the role of employee
                  </MenuItem>
                  <MenuItem value="transcriber">
                    {getTaskName("transcriber")}
                  </MenuItem>
                  <MenuItem value="translator">
                    {getTaskName("translator")}
                  </MenuItem>
                  <MenuItem value="performancedirection">
                    {getTaskName("performancedirection")}
                  </MenuItem>
                  <MenuItem value="speakerfaceidentification">
                    {getTaskName("speakerfaceidentification")}
                  </MenuItem>
                  <MenuItem value="lipqualityassurance">
                    {getTaskName("lipqualityassurance")}
                  </MenuItem>
                  <MenuItem value="qualityassurance">
                    {getTaskName("qualityassurance")}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <GlobalButton
                  onClick={() => {
                    // if (selectEmployee === "select an to add user") return;
                    if (selectRole === "select the role of the employee") return;

                    // Use the stored user object instead of searching through employes
                    if (!selectedUserObj) {
                      toast.error("Please select a valid employee");
                      return;
                    }

                    // Check if user is already in displaySelection
                    // const isUserAlreadySelected = displaySelection.some(
                    //   item => item.id === selectedUserObj.id
                    // );

                    // if (isUserAlreadySelected) {
                    //   toast.error("This user is already assigned to the project");
                    //   return;
                    // }

                    const newItem = {
                      name: selectedUserObj.name,
                      role: selectRole,
                      id: selectedUserObj.id,
                    };
                    setDisplaySelection([...displaySelection, newItem]);

                    // Reset selections
                    // setSelectEmployee("select an to add user");
                    setSelectRole("select the role of the employee");
                    // setSelectedUserObj(null);
                  }}
                >
                  Add Employee
                </GlobalButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="column" marginTop={5}>
            {/* <Grid item className={classes["midHeader"]} marginBottom={1.5}>
              Project Members
            </Grid> */}
            <Grid item container xs={10} marginBottom={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>ROLE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displaySelection.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{getTaskName(user.role)}</TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => handleRemoveEmployes(user.name)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid item container direction="column" marginTop={5}>
            {/* <Grid
              item
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                // borderBottom: "1px solid #ebebeb",
              }}
              marginBottom={1.5}
              marginLeft={2}
            >
              Finish
            </Grid> */}
            <Grid item>
              <GlobalButton
                variant="contained"
                onClick={() => handleSubmitCreation()}
              >
                CREATE
              </GlobalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectCreationScreen;

const getVoiceName = (voiceId) => {
  // Split the voice ID into type and actual ID
  const [type, id] = voiceId.split(':');

  const elevenLabsVoices = {
    'cjDX3gheKRFt01mtnlgO': 'Marcus',
    'KSe5Ky8JOOa2PsUdz0qx': 'Alex',
    'kOs9fYMiSchRrS2JIwha': 'Neal',
    'EiNlNiXeDU1pqqOPrYMO': 'John',
    'uju3wxzG5OhpWcoi3SMy': 'Michael',
    'NFG5qt843uXKj4pFvR7C': 'Adam',
    'm6T9tbctbKAnYvdN4nrj': 'Bria',
    'lxYfHSkYm1EzQzGhdbfc': 'Jessica',
    'U4QF22fMv0qiB9yCLXKL': 'Maya',
    'i4CzbCVWoqvD0P1QJCUL': 'Ivy',
    '5l5f8iK3YPeGga21rQIX': 'Adeline',
    'rCmVtv8cYU60uhlsOo1M': 'Ana'
  };

  const customVoices = {
    'Male_audiobook_1': 'James',
    'Male_audiobook_2': 'Robert',
    'Male_audiobook_3': 'William',
    'Female_audiobook_1': 'Emma',
    'Female_audiobook_2': 'Sophia',
    'Female_audiobook_3': 'Olivia'
  };

  if (type === 'elevenlabs') {
    return elevenLabsVoices[id] || '';
  } else if (type === 'custom') {
    return customVoices[id] || '';
  }
  return '';
};